import { IBetweenDates } from '@interfaces';

export function betweenDatesPreview(btd: IBetweenDates): string {
  let preview;
  let prevFunc = function (offset: number, unit: string) {
    // if (unit === 'day' && offset === 0) return 'today';
    return `${offset === 0 ? 'the current' : Math.abs(offset)} ${unit}${Math.abs(offset) > 1 ? 's' : ''}${
      offset === 0 ? '' : offset > 0 ? ' from now' : ' ago'
    }`;
  };
  const nth = (n) => {
    return n + (['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th');
  };
  const startHourText =
    btd.start_unit === 'day'
      ? btd.start_hour === 0
        ? 'beginning'
        : btd.start_hour === 23.99
        ? 'end'
        : nth(btd.start_hour) + ' hour'
      : 'beginning';
  const endHourText =
    btd.end_unit === 'day'
      ? btd.end_hour === 0
        ? 'beginning'
        : btd.end_hour === 23.99
        ? 'end'
        : nth(btd.end_hour) + ' hour'
      : 'end';
  if (btd.start_unit === 'none' && btd.end_unit === 'none') return 'All Dates Included';
  else if (btd.start_unit === 'none') {
    preview = `Before the ${endHourText} of ${prevFunc(btd.end_offset, btd.end_unit)}`;
  } else if (btd.end_unit === 'none') {
    preview = `After the  of ${prevFunc(btd.start_offset, btd.start_unit)}`;
  } else {
    preview = `Between the ${startHourText} of ${prevFunc(
      btd.start_offset,
      btd.start_unit,
    )} and the ${endHourText} of ${prevFunc(btd.end_offset, btd.end_unit)}`;
  }
  return preview;
}
