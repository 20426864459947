<nb-card class="dialog-card">
  <nb-card-body>
    <div class="headertext">
      <h5>Advanced Value</h5>
      <resplendent-close-button
        (click)="CloseDialog()"
      ></resplendent-close-button>
    </div>
    <br />
    <!--constant advanced value stuff (like value type)-->
    <div class="sep-row">
      <div class="SimpleRow">
        <div class="col-begin value-selection">
          <label class="label">Value Type</label>
          <nb-select [(selected)]="filterItem.advancedValueType">
            <nb-option
              *ngFor="let ValueType of ValueTypes"
              [value]="ValueType.value"
              (click)="SelectValueType(ValueType.value)"
              >{{ ValueType.label }}</nb-option
            >
          </nb-select>
        </div>
        <nb-icon
          class="info-icon"
          icon="info-outline"
          [nbTooltip]="
            ValueDescriptions[filterItem.advancedValueType].description
          "
        ></nb-icon>
      </div>

      <div class="col-begin">
        <label class="label"> Select a timezone</label>
        <resplendent-autocomplete
          class="variable-autocomplete"
          [value]="filterItem.advancedValue.timezone"
          (valueChanges)="selectTimezone($event)"
          [possibleValues]="availableTimezones"
          [disabled]="
            filterItem.advancedValueType === undefined ||
            !(
              filterItem.advancedValueType === 'datetime' ||
              filterItem.advancedValueType === 'date' ||
              filterItem.advancedValueType === 'between_dates'
            )
          "
        >
        </resplendent-autocomplete>
      </div>
    </div>
    <br />
    <!-- timedelta stuff -->
    <div class="sep-row" *ngIf="filterItem.advancedValueType === 'timedelta'">
      <div>
        <nb-checkbox [(checked)]="NegativeOffset" (click)="NegativeClick()">
          Negative Time Delta
        </nb-checkbox>
        <h6>{{ TimeDeltaString }}</h6>
      </div>
      <div class="SimpleRow">
        <div class="SimpleColumn">
          <label class="label">Days</label>
          <input
            nbInput
            [value]="OffsetDict['d']"
            (keyup)="SaveOffset($event, 'd')"
            type="text"
          />
        </div>
        <div class="SimpleColumn">
          <label class="label">Hours</label>
          <input
            nbInput
            [value]="OffsetDict['h']"
            (keyup)="SaveOffset($event, 'h')"
            type="text"
          />
        </div>
        <div class="SimpleColumn">
          <label class="label">Minutes</label>
          <input
            nbInput
            [value]="OffsetDict['m']"
            (keyup)="SaveOffset($event, 'm')"
            type="text"
          />
        </div>
        <div class="SimpleColumn">
          <label class="label">Seconds</label>
          <input
            nbInput
            [value]="OffsetDict['s']"
            (keyup)="SaveOffset($event, 's')"
            type="text"
          />
        </div>
      </div>
    </div>
    <!--datetime and date stuff-->
    <div
      class="sep-row"
      *ngIf="
        filterItem.advancedValueType !== undefined &&
        (filterItem.advancedValueType === 'datetime' ||
          filterItem.advancedValueType === 'date')
      "
    >
      <div class="col-begin">
        <label class="label"> Select a time type </label>
        <!-- <nb-checkbox [(checked)]="OnlyBusinessDays" (checkedChange)="BusinessDaysClick($event)">
            Only Business Days
          </nb-checkbox> -->
        <nb-select [(selected)]="filterItem.advancedValue['time_type']">
          <nb-option value="relative_time">Relative Time</nb-option>
          <nb-option value="current_time">Current Time</nb-option>
          <nb-option value="static_time"
            >Static
            {{
              ValueDescriptions[filterItem.advancedValueType].label
            }}</nb-option
          >
        </nb-select>
      </div>

      <div
        *ngIf="
          filterItem.advancedValue['time_type'] === 'current_time';
          else StaticTime
        "
        class="current-time"
      >
        <label class="label"> Offset from the current time </label>
        <div>
          <div class="sep-row-mid">
            <nb-checkbox [(checked)]="NegativeOffset" (click)="NegativeClick()">
              Negative Offset
            </nb-checkbox>
            <label class="label">{{ TimeDeltaString }}</label>
          </div>

          <div
            class="SimpleRow"
            *ngIf="
              filterItem.advancedValueType === 'datetime';
              else dateOnlyInputs
            "
          >
            <div class="SimpleColumn">
              <label class="label">Days</label>
              <input
                nbInput
                [value]="OffsetDict['d']"
                (keyup)="SaveOffset($event, 'd')"
                type="text"
              />
            </div>
            <div class="SimpleColumn">
              <label class="label">Hours</label>
              <input
                nbInput
                [value]="OffsetDict['h']"
                (keyup)="SaveOffset($event, 'h')"
                type="text"
              />
            </div>
            <div class="SimpleColumn">
              <label class="label">Minutes</label>
              <input
                nbInput
                [value]="OffsetDict['m']"
                (keyup)="SaveOffset($event, 'm')"
                type="text"
              />
            </div>
            <div class="SimpleColumn">
              <label class="label">Seconds</label>
              <input
                nbInput
                [value]="OffsetDict['s']"
                (keyup)="SaveOffset($event, 's')"
                type="text"
              />
            </div>
          </div>
          <ng-template #dateOnlyInputs>
            <div class="SimpleRow">
              <div class="SimpleColumn">
                <label class="label">Days</label>
                <input
                  nbInput
                  [value]="OffsetDict['d']"
                  (keyup)="SaveOffset($event, 'd')"
                  type="text"
                />
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <ng-template #StaticTime>
        <div
          *ngIf="
            filterItem.advancedValue['time_type'] === 'static_time';
            else RelativeTime
          "
        >
          <label class="label">
            Pick a Static
            {{ ValueDescriptions[filterItem.advancedValueType].label }}
          </label>
          <div>
            <input
              nbInput
              [formControl]="DateFormControl"
              [nbDatepicker]="datepicker"
            />
            <nb-datepicker #datepicker></nb-datepicker>
            <div
              class="SimpleRow"
              *ngIf="filterItem.advancedValueType === 'datetime'"
            >
              <div class="SimpleColumn">
                <label class="label">Hour</label>
                <nb-select [(selected)]="Hour">
                  <nb-option
                    (click)="UpdateTime()"
                    *ngFor="let num of listTo24"
                    value="{{ num }}"
                    >{{ num }}</nb-option
                  >
                </nb-select>
              </div>
              <div class="SimpleColumn">
                <label class="label">Minute</label>
                <nb-select [(selected)]="Minute">
                  <nb-option
                    (click)="UpdateTime()"
                    *ngFor="let num of listTo60"
                    value="{{ num }}"
                    >{{ num }}</nb-option
                  >
                </nb-select>
              </div>
              <div class="SimpleColumn">
                <label class="label">Second</label>
                <nb-select [(selected)]="Second">
                  <nb-option
                    (click)="UpdateTime()"
                    *ngFor="let num of listTo60"
                    value="{{ num }}"
                    >{{ num }}</nb-option
                  >
                </nb-select>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template #RelativeTime>
        <div class="relative-time-stuff">
          <div class="col-begin">
            <label class="label"> Pick a Relative Time </label>
            <nb-select [(selected)]="filterItem.advancedValue.beginning_or_end">
              <nb-option value="beginning">Beginning of the</nb-option>
              <nb-option value="end">End of the</nb-option>
            </nb-select>
          </div>
          <nb-select
            [(selected)]="filterItem.advancedValue.between_dates.start_unit"
          >
            <nb-option
              *ngFor="
                let opt of [
                  { value: 'day', label: 'Current Day' },
                  { value: 'week', label: 'Current Week' },
                  { value: 'month', label: 'Current Month' },
                  { value: 'quarter', label: 'Current Quarter' },
                  { value: 'year', label: 'Current Year' }
                ]
              "
              [value]="opt.value"
              (click)="
                filterItem.advancedValue.between_dates.end_unit = $any(
                  opt.value
                )
              "
              >{{ opt.label }}</nb-option
            >
          </nb-select>
          <div class="SimpleColumn">
            <label class="label">offset</label>
            <input
              type="number"
              nbInput
              [value]="filterItem.advancedValue.between_dates.start_offset"
              (input)="setDatetimeBetweenDatesOffset($event)"
            />
          </div>
        </div>
      </ng-template>
    </div>

    <!-- boolean select stuff -->
    <div
      class="row"
      *ngIf="
        filterItem.advancedValueType !== undefined &&
        filterItem.advancedValueType === 'boolean'
      "
    >
      <div class="col-begin">
        <label class="label">Toggle value</label>
        <nb-toggle
          status="primary"
          [(checked)]="filterItem.advancedValue.value"
          >{{ filterItem.advancedValue.value ? 'True' : 'False' }}</nb-toggle
        >
      </div>
    </div>
    <!--column stuff-->
    <div class="sep-row" *ngIf="filterItem.advancedValueType === 'column'">
      <div class="col-begin">
        <label class="label">Column</label>
        <nb-select
          [(selected)]="filterItem.advancedValue.column"
          placeholder="Select a column"
          class="col-select"
        >
          <nb-option *ngFor="let col of columns" [value]="col.name">
            {{ col.alias ? col.alias : col.name }}
          </nb-option>
        </nb-select>
      </div>
      <div class="col-begin">
        <label class="label"> Force Datatype </label>
        <nb-select
          [(selected)]="filterItem.advancedValue.force_datatype"
          class="force-dtype-select"
        >
          <nb-option
            *ngFor="let dtype of AvailableDatatypes"
            [value]="dtype.value"
            (click)="selectForceDatatype(dtype.value)"
          >
            {{ dtype.label }}
          </nb-option>
        </nb-select>
      </div>
    </div>

    <!--colum offset stuff-->
    <div
      *ngIf="
        filterItem.advancedValueType === 'column' &&
          filterItem.advancedValue.force_datatype !== 'none';
        else OffsetDisabledText
      "
    >
      <br />
      <div class="sep-row">
        <nb-checkbox
          [(checked)]="filterItem.advancedValue.use_offset"
          nbTooltip="If you use offset it will be added to the second column's value before it is compared with the first column's value."
        >
          Use Offset
        </nb-checkbox>

        <div *ngIf="filterItem.advancedValue.use_offset; else NoOffsetText">
          <label class="label"> Offset </label>
          <div
            *ngIf="
              filterItem.advancedValue.force_datatype === 'float';
              else integerForm
            "
          >
            <input
              nbInput
              [value]="ColumnOffsetDict['float']"
              (keyup)="SaveColumnOffset($event, 'float')"
              type="text"
            />
          </div>
          <ng-template #integerForm>
            <div
              *ngIf="
                filterItem.advancedValue.force_datatype === 'int';
                else stringForm
              "
            >
              <input
                nbInput
                [value]="ColumnOffsetDict['int']"
                (keyup)="SaveColumnOffset($event, 'int')"
                type="text"
              />
            </div>
          </ng-template>

          <ng-template #stringForm>
            <div
              *ngIf="
                filterItem.advancedValue.force_datatype === 'string';
                else datetimeForm
              "
            >
              <input
                nbInput
                [value]="ColumnOffsetDict['string']"
                (keyup)="SaveColumnOffset($event, 'string')"
                type="text"
              />
            </div>
          </ng-template>

          <ng-template #datetimeForm>
            <div
              *ngIf="
                filterItem.advancedValue.force_datatype === 'datetime';
                else dateForm
              "
            >
              <div class="sep-row">
                <nb-checkbox
                  [(checked)]="NegativeColumnOffset"
                  (click)="NegativeColumnClick()"
                >
                  Negative Offset
                </nb-checkbox>

                <label class="label">{{ ColumnTimeDeltaString }}</label>
              </div>

              <div class="SimpleRow">
                <div class="SimpleColumn">
                  <label class="label">Days</label>
                  <input
                    nbInput
                    [value]="ColumnTimeOffsetDict['d']"
                    (keyup)="SaveColumnOffset($event, 'date', 'd')"
                    type="text"
                  />
                </div>
                <div class="SimpleColumn">
                  <label class="label">Hours</label>
                  <input
                    nbInput
                    [value]="ColumnTimeOffsetDict['h']"
                    (keyup)="SaveColumnOffset($event, 'date', 'h')"
                    type="text"
                  />
                </div>
                <div class="SimpleColumn">
                  <label class="label">Minutes</label>
                  <input
                    nbInput
                    [value]="ColumnTimeOffsetDict['m']"
                    (keyup)="SaveColumnOffset($event, 'date', 'm')"
                    type="text"
                  />
                </div>
                <div class="SimpleColumn">
                  <label class="label">Seconds</label>
                  <input
                    nbInput
                    [value]="ColumnTimeOffsetDict['s']"
                    (keyup)="SaveColumnOffset($event, 'date', 's')"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </ng-template>

          <ng-template #dateForm>
            <div *ngIf="filterItem.advancedValue.force_datatype === 'date'">
              <nb-checkbox
                [(checked)]="NegativeColumnOffset"
                (click)="NegativeColumnClick()"
              >
                Negative Offset
              </nb-checkbox>

              <h6>{{ ColumnTimeDeltaString }}</h6>

              <div class="SimpleRow">
                <div class="SimpleColumn">
                  <label class="label">Days</label>
                  <input
                    nbInput
                    [value]="ColumnTimeOffsetDict['d']"
                    (keyup)="SaveColumnOffset($event, 'date', 'd')"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </ng-template>
        </div>
        <ng-template #NoOffsetText>
          <label class="label"> No Offset </label>
        </ng-template>
      </div>
    </div>
    <ng-container *ngIf="filterItem.advancedValueType === 'between_dates'">
      <div class="sep-row">
        <div class="between-row">
          <div class="col-begin">
            <label class="label"> Beginning</label>
            <nb-select
              class="between-select"
              [(selected)]="filterItem.advancedValue.start_unit"
            >
              <nb-option
                *ngFor="let option of between_dates_options"
                [value]="option.value"
                (click)="generate_between_dates_preview()"
              >
                {{ option.label }}
              </nb-option>
            </nb-select>
            <label class="label"> Hour</label>
            <nb-select
              class="between-select"
              [(selected)]="filterItem.advancedValue.start_hour"
            >
              <nb-option
                *ngFor="let option of betweenDatesHourOptions"
                [value]="option"
                (click)="generate_between_dates_preview()"
              >
                {{ option === 23.99 ? 'End of Day' : option }}
              </nb-option>
            </nb-select>
          </div>
          <div class="col-begin offset-margin">
            <label class="label">offset</label>
            <input
              type="text"
              nbInput
              class="between-input ml-2"
              [value]="filterItem.advancedValue.start_offset"
              (change)="
                save_between_dates_offset($event, true);
                generate_between_dates_preview()
              "
            />
          </div>
        </div>

        <div class="between-row">
          <div class="col-begin">
            <label class="label"> End </label>
            <nb-select
              class="between-select"
              [(selected)]="filterItem.advancedValue.end_unit"
            >
              <nb-option
                *ngFor="let option of between_dates_options"
                [value]="option.value"
                (click)="generate_between_dates_preview()"
              >
                {{ option.label }}
              </nb-option>
            </nb-select>
            <label class="label"> Hour</label>
            <nb-select
              class="between-select"
              [(selected)]="filterItem.advancedValue.end_hour"
            >
              <nb-option
                *ngFor="let option of betweenDatesHourOptions"
                [value]="option"
                (click)="generate_between_dates_preview()"
              >
                {{ option === 23.99 ? 'End of Day' : option }}
              </nb-option>
            </nb-select>
          </div>
          <div class="col-begin offset-margin">
            <label class="label">offset</label>
            <input
              type="text"
              nbInput
              class="between-input ml-2"
              [value]="filterItem.advancedValue.end_offset"
              (change)="
                save_between_dates_offset($event, false);
                generate_between_dates_preview()
              "
            />
          </div>
        </div>
      </div>
      <br />
      <div class="col-begin">
        <label class="label">Preview</label>
        <div class="wrapperDiv">
          <textarea
            class="between-dates-description"
            type="text"
            nbInput
            fullWidth
            [value]="between_dates_preview"
          ></textarea>
          <div class="overlayDiv"></div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="filterItem.advancedValueType === 'filter_variable'">
      <label class="label"
        >Choose a filter variable to use as the value for this filter.</label
      >
      <div class="variable-autocomplete">
        <resplendent-autocomplete
          [value]="filterItem.advancedValue.variable_id"
          (valueChanges)="filterItem.advancedValue.variable_id = $event"
          [possibleValues]="filterVariableIds$ | async"
          [aliasMap]="filterVariableIdsToNames$ | async"
          size="medium"
          shape="rectangle"
          placeholder="Choose a filter variable"
        ></resplendent-autocomplete>
      </div>
      <ng-container *ngFor="let variable of filterVariables$ | async">
        <ng-container
          *ngIf="variable.id === filterItem.advancedValue.variable_id"
        >
          <div *ngIf="variable.values.length === 0">
            <p>This variable doesn't have any values assigned to it</p>
          </div>
          <div *ngIf="variable.values.length > 0">
            <h6>Values of this filter variable</h6>
            <nb-list>
              <nb-list-item *ngFor="let value of variable.values">
                {{ showValue(value.value) }}
              </nb-list-item>
            </nb-list>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <!--text to show if the user has none selected as the forced datatype-->
    <ng-template #OffsetDisabledText>
      <div
        *ngIf="
          filterItem.advancedValue !== undefined &&
          filterItem.advancedValue?.force_datatype === 'none' &&
          filterItem.advancedValueType === 'column'
        "
        class="SimpleColumn"
      >
        <br />
        <label class="label"
          >To enable offsets you must force a datatype.</label
        >
      </div>
    </ng-template>
  </nb-card-body>
</nb-card>
