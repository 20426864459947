export class TimeDelta {
  flatDays = 0;
  hourOfDay = 0;
  MinuteOfHour = 0;
  SecondOfMinute = 0;

  Days;
  Hours;
  Minutes;
  Seconds;

  IsNegative = false;

  constructor(time, timeScale = 's') {
    switch (timeScale) {
      case 'ms': {
        this.set(time / 1000);
        break;
      }

      case 's': {
        this.set(time);
        break;
      }

      case 'm': {
        this.set(time * 60);
        break;
      }

      case 'h': {
        this.set(time * 3600);
        break;
      }

      case 'd': {
        this.set(time * 86400);
        break;
      }

      case 'w': {
        this.set(time * 604800);
        break;
      }

      default: {
        this.set(time);
        break;
      }
    }
  }

  set(seconds) {
    if (seconds < 0) {
      this.IsNegative = true;
      seconds = -parseFloat(seconds);
    }

    this.Days = seconds / 86400;
    this.Hours = seconds / 3600;
    this.Minutes = seconds / 60;
    this.Seconds = seconds;

    this.flatDays = Math.floor(this.Days);
    this.hourOfDay = Math.floor((seconds % 86400) / 3600);
    this.MinuteOfHour = Math.floor((seconds % 3600) / 60);
    this.SecondOfMinute = Math.floor(seconds % 60);
  }
  add(...TimeDeltas: TimeDelta[]) {
    let seconds = this.Seconds;
    for (let delta of TimeDeltas) {
      seconds += delta.Seconds;
    }
    return new TimeDelta(seconds);
  }

  toReadable() {
    let stringTimeDelta = this.IsNegative ? '-' : '';

    stringTimeDelta += this.flatDays == 0 ? '' : this.flatDays + (this.flatDays == 1 ? ' Day, ' : ' Days, ');
    stringTimeDelta += this.hourOfDay + ':';
    if ((this.MinuteOfHour + '').split('').length > 1) {
      stringTimeDelta += this.MinuteOfHour + ':';
    } else {
      stringTimeDelta += '0' + this.MinuteOfHour + ':';
    }
    if ((this.SecondOfMinute + '').split('').length > 1) {
      stringTimeDelta += this.SecondOfMinute;
    } else {
      stringTimeDelta += '0' + this.SecondOfMinute;
    }

    return stringTimeDelta;
  }

  toShortReadable() {
    let stringTimeDelta = this.IsNegative ? '-' : '';

    stringTimeDelta += this.flatDays == 0 ? '' : this.flatDays + 'd';

    if (this.hourOfDay !== 0) {
      if (this.flatDays !== 0) stringTimeDelta += ' ';
      stringTimeDelta += this.hourOfDay + 'h';
    }

    if (this.MinuteOfHour !== 0) {
      if (this.hourOfDay !== 0 || this.flatDays !== 0) stringTimeDelta += ' ';
      stringTimeDelta += this.MinuteOfHour + 'm';
    }

    if (this.SecondOfMinute !== 0) {
      if (this.MinuteOfHour !== 0 || this.hourOfDay !== 0 || this.flatDays !== 0) stringTimeDelta += ' ';
      stringTimeDelta += this.SecondOfMinute + 's';
    }

    return stringTimeDelta;
  }
}
